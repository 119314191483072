import { hot } from 'react-hot-loader/root';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import primaryColor from '@material-ui/core/colors/blue';

import LinearProgress from '@material-ui/core/LinearProgress';

import { toast } from 'react-toastify';

import PrivateRoute from './components/PrivateRoute';
// import MyCounter from './MyCounter'
import { SnackbarProvider } from 'notistack';


const loading = () => <LinearProgress />;

const Login = React.lazy(() => import('./pages/Login'));
const Page404 = React.lazy(() => import('./pages/Page404'));
const Page500 = React.lazy(() => import('./pages/Page500'));

const DefaultLayout = React.lazy(() => import('./layouts/DefaultLayout'));

const theme = createMuiTheme({
  palette: {
    primary: { main: '#3C3B53' },
    secondary: { main: '#3C3B53' },
  },
});

toast.configure();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}>
        <Router>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route path="/login" render={props => <Login {...props} />} />
              <Route path="/404" render={props => <Page404 {...props} />} />
              <Router path="/500" render={props => <Page500 {...props} />} />
              <PrivateRoute path="/" render={props => <DefaultLayout {...props} />} />
            </Switch>
          </React.Suspense>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default hot(App);
