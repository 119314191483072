import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const loading = () => <div>Loading...</div>;

function isAuthenticated() {
  let token = localStorage.getItem('token');
  return token != null;
}

const PrivateRoute = ({ component: Component, render, ...rest }) => (
  <Route {...rest} render={(props) => (
    isAuthenticated()
      ? <React.Suspense fallback={loading()}>
        <Route render={render} />
      </React.Suspense>
      : <Redirect to='/login' />
  )} />
);

export default PrivateRoute;